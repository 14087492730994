$opensans:'Open Sans', sans-serif;
$bebas:'Bebas Neue', sans-serif;

$musoraBlack:#000C17;
$pianoteRed: #F61A30;
$muOrange:#F4691C;
$coachesOrange:#fe9f13;
$recordeoYellow:#ffae00;
$muGold:#FFAE00;
$muYellow:#FFD900;
$muGreen:#8FBC50;
$meGreen:#00BC75;
$guitareoGreen: #00C9AC;
$muBlue:#00BEDD;
$songsBlue:#17d1fa;
$drumeoBlue:#0b76db;
$singeoPurple:#8300E9;
$muMagenta:#B70C96;
$songsPink:#ec0061;

$youtube:#cd201f;
$facebook:#3b5998;
$instagram:#e1306c;

$promoColor:#d61922;

@mixin sm {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

//todo remove from pianote scss
@mixin desktopOld {
    @media (min-width: 991px) {
        @content;
    }
}
