@import "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap";
h1 {
  margin: 0 auto 5px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.1em;
}

@media (width >= 768px) {
  h1 {
    font-size: 28px;
  }
}

@media (width >= 1024px) {
  h1 {
    font-size: 35px;
  }
}

h3 {
  margin: 0 auto 35px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3em;
}

@media (width >= 768px) {
  h3 {
    margin: 0 auto 40px;
    font-size: 18px;
  }
}

@media (width >= 1024px) {
  h3 {
    margin: 0 auto 55px;
    font-size: 21px;
  }
}

#sticky-slide .online-atc.merch .join {
  background: #777;
}

#sticky-slide .online-atc.merch.active .join {
  background: #10d05f;
}

#sticky-slide .online-atc.merch.active .join:hover {
  background: #25ee78;
}

#sticky-slide .join {
  width: 100%;
  max-width: 310px;
  margin: 7px auto;
  padding: 10px;
  font-size: 20px;
  transition: all .3s;
}

@media (width >= 768px) {
  #sticky-slide .join {
    margin: 15px auto;
    padding: 13px 10px;
  }
}

@media (width >= 1024px) {
  #sticky-slide .join {
    padding: 20px 10px;
  }
}

#sticky-slide .join.bundle-option {
  margin: 5px auto 15px;
}

#sticky-slide .pack-pick.error {
  background-color: #ffc7c7;
  border-color: #f71b26;
}

#sticky-slide .pack-pick option[disabled] {
  color: #ccc;
}

#sticky-slide .selected-pack .join {
  background: #8c9698;
}

#sticky-slide .selected-pack.active .join {
  background: #10d05f;
}

#sticky-slide .selected-pack.active .join:hover {
  opacity: .9;
}

.pack-details .slider-container .slider-nav .slick-slide {
  cursor: pointer;
  height: initial;
  outline: none;
  padding: 7px 3px;
  position: relative;
}

.pack-details .drumshop-accordion .interactive-logo {
  max-width: 210px;
}

@media (width >= 768px) {
  .pack-details .drumshop-accordion .interactive-logo {
    max-width: 290px;
  }
}

.pack-details .bonus-pic .image-wrap {
  min-width: 140px;
  max-width: 140px;
}

@media (width >= 1024px) {
  .pack-details .bonus-pic .image-wrap {
    min-width: 170px;
    max-width: 170px;
  }
}

.pack-details .bonus-pic .image-wrap.shipping:after {
  content: "FREE SHIPPING";
  color: #fff;
  text-align: center;
  background: #0b76db;
  border-radius: 8px 8px 0 0;
  padding: 4px 0;
  font: 700 13px / 1em Open Sans, sans-serif;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.pack-details table.specTable td, .pack-details table.specTable th {
  padding: 7px;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
}

@media (width >= 768px) {
  .pack-details table.specTable td, .pack-details table.specTable th {
    font-size: 16px;
  }
}

.pack-details table.specTable tr:nth-child(2n) {
  background: #eee;
}
/*# sourceMappingURL=drum-shop-product.css.map */
